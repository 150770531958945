import BasicSection from "components/BasicSection";
import FAQSection, {
    FAQHeading,
    FAQUnclearSection,
    IFAQSectionEntry,
    makeSections,
    sectionNamesIndexPage,
} from "components/FAQSection";
import Layout from "components/Layout";
import { graphql, PageProps } from "gatsby";
import React from "react";

interface DataProps {
    allMdx: {
        group: {
            nodes: IFAQSectionEntry[];
        }[];
    };
}

const FAQIndex: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const groups = data.allMdx.group;

    const sections = makeSections(groups, sectionNamesIndexPage);

    return (
        <Layout title="Ente - FAQ" path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <FAQHeading />
                </BasicSection.Header>

                <BasicSection.Body>
                    <div className="grid2x2-md-container align-items-start">
                        {sections.map((section) => (
                            <FAQSection
                                key={section.name}
                                section={section}
                                className="grid2x2-md-item"
                            />
                        ))}
                    </div>
                    <FAQUnclearSection />
                </BasicSection.Body>
            </BasicSection>
        </Layout>
    );
};

export default FAQIndex;

export const query = graphql`
    query {
        allMdx(
            filter: { fields: { sourceInstanceName: { eq: "faq" } } }
            sort: { fields: frontmatter___index, order: ASC }
        ) {
            group(field: frontmatter___parent) {
                nodes {
                    frontmatter {
                        title
                        parent
                    }
                    fields {
                        slug
                        sourceInstanceName
                    }
                    id
                }
            }
        }
    }
`;
